import DataService from "./dataService";
import config from "../common/config";
import { getPagingLimit } from "../common/storage";
import { getByPageOrder } from "../common/functions";

class ApiStudent {
    _converFilter(filter) {
        var result = '';
        if (filter && filter.name) result += `&full_name=${filter.name}`;
        if (filter && typeof(filter.status) != 'undefined' && filter.status != "") result += `&status=${filter.status}`;
        if (filter && typeof(filter.tuition_debt) != 'undefined' && filter.tuition_debt != "") result += `&tuition_debt=${filter.tuition_debt}`;
        if (filter && filter.stadium) result += `&stadium_id=${filter.stadium}`;
        if (filter && filter.province) result += `&province_id=${filter.province}`;
        if (filter && filter.district) result += `&district_id=${filter.district}`;
        if (filter && filter.date) result += `&date=${filter.date}`;
        return result;
    }
    getPaging(paging, orders, filter) {
        const limit = getPagingLimit();
        return DataService.get(`/student?${ getByPageOrder(paging, orders, limit ? limit : config.PER_PAGE) }${this._converFilter(filter)}`);
    }
    get(id) {
        return DataService.get(`/student_detail?id=${id}`);
    }
    addOrUpdate(data) {
        return DataService.post("/student", data);
    }
    delete(id) {
        return DataService.delete(`/student?id=${id}`);
    }
    listPay(paging, orders, studentId = null) {
        const limit = getPagingLimit();
        const params = studentId ? `&student_id=${studentId}` : '';
        return DataService.get(`/student_pay?${ getByPageOrder(paging, orders, limit ? limit : config.PER_PAGE) }${params}`);
    }
    addOrUpdatePay(data) {
        return DataService.post("/student_pay", data);
    }
    deletePay(id) {
        return DataService.delete(`/student_pay?id=${id}`);
    }
    changeCalendar(data) {
        return DataService.post("/student_calendar", data);
    }
    getCalendar(payId) {
        return DataService.get(`/student_calendar?pay_id=${payId}`);
    }
}

export default new ApiStudent();