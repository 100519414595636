<template>
  <div id="module-student">
    <CoolLightBox :items="coolLightImages" :index="indexViewImg" @close="indexViewImg = null" />
    <div class="card shadow filter" v-if="itemFilter">
      <div class="card-header p-1">
        <div class="card-title w-100">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
            <input type="search" class="form-control" id="filter_name" placeholder="Tìm kiếm" v-model="itemFilter.name"
              v-on:keyup="keyEnter" autocomplete="off">
            <div class="input-group-append">
              <button type="button" class="btn btn-info btn-sm" v-on:click="showHideFilter(showFilter)"
                style="width: 40px;">
                <i class="fas fa-chevron-left" v-if="!showFilter"></i>
                <i class="fas fa-chevron-down" v-if="showFilter"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pb-3" v-if="showFilter">
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="form-group mb-0">
              <label for="filter_stadium">Sân vận động</label>
              <InputStadium ref="inputStadium" :province="userLogin.province_id" :district="userLogin.district_id"
                v-on:keyup="keyEnter" :callBack="setStadiumFilter" />
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="form-group">
              <label for="filter_status">Tình trạng</label>
              <b-form-select id="filter_status" v-model="itemFilter.status" :options="filterStatusStudentOptions"
                v-on:keyup="keyEnter">
              </b-form-select>
            </div>
            <div class="form-group">
              <label for="filter_tuition_debt">Học phí</label>
              <b-form-select id="filter_tuition_debt" v-model="itemFilter.tuition_debt" :options="filterMoneyOptions"
                v-on:keyup="keyEnter">
              </b-form-select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer" style="border-bottom: 1px solid rgba(0,0,0,.125);" v-if="showFilter">
        <div class="btn-group float-right">
          <button type="button" class="btn btn-info btn-sm" v-on:click="initItems()"><i
              class="fas fa-search mr-2"></i><span>Tìm kiếm</span></button>
          <button type="button" class="btn btn-default btn-sm" v-on:click="clearFilter()"><i
              class="fas fa-eraser mr-2"></i><span>Làm mới</span></button>
        </div>
      </div>
    </div>
    <div class="card shadow">
      <div class="card-body p-0">
        <table class="table size-website">
          <thead>
            <tr>
              <th style="width: 60px"></th>
              <th>
                <Order title="Tên" column="first_name" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Tình trạng" column="status" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Tên phụ huynh" column="parent_name" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Liên hệ" column="phone_number" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Sân vận động" column="stadium_name" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Ngày đăng ký" column="created_at" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th>
                <Order title="Học phí" column="tuition_debt" :callBack="orderBy" :orders="itemOrders" />
              </th>
              <th style="width: 79px;">
                <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                  <i class="fas fa-plus-circle"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-if="items && items.items">
            <tr v-for="(item, index) in items.items" :key="index">
              <td class="text-center" v-on:click="indexViewImg = index">
                <b-avatar :src="fullPathFile(item.img)" class="elevation-2" size="38px" variant="default" />
              </td>
              <td>
                <router-link class="text-bold" v-bind:to="'/student/detail/' + item.id">
                  {{ item.first_name }} {{ item.last_name }}
                </router-link>
                <div class="text-sm"><i class="fas fa-birthday-cake mr-2"></i>{{ formatDate(item.dob) }}</div>
              </td>
              <td>{{ getTextOption(item.status.toString(), statusStudentOptions) }}</td>
              <td>{{ item.parent_name }}</td>
              <td>
                <div>{{ formatPhone(item.phone_number) }}</div>
                <div><i class="text-sm">{{ item.address }}</i></div>
              </td>
              <td>
                <div>{{ item.stadium_name }}</div>
                <div><i class="text-sm">{{ item.stadium_address }}</i></div>
              </td>
              <td class="text-center">{{ formatDate(item.created_at) }}</td>
              <td class="text-center">
                <i class="text-sm text-bold text-danger" v-if="item.tuition_debt == 1">Nợ học phí</i>
                <!-- <i class="text-success text-sm" v-else>Đã đóng</i> -->
              </td>
              <td>
                <div class="btn-group">
                  <button class="btn btn-info btn-sm" v-on:click="updateItem(item)"><i class="fas fa-edit"></i></button>
                  <button class="btn btn-default btn-sm" v-on:click="deleteItem(item)"><i
                      class="far fa-trash-alt"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
          <Paging v-if="items" :items="items" column="9" :callBack="pagingBy" />
        </table>
        <table class="table size-mobile">
          <thead>
            <tr>
              <th style="width: 60px">
                <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                  <i class="fas fa-plus-circle"></i>
                </button>
              </th>
              <th>
                <Order title="Học viên" column="first_name" :callBack="orderBy" :orders="itemOrders" />
              </th>
            </tr>
          </thead>
          <tbody v-if="items && items.items">
            <tr v-for="(item, index) in items.items" :key="index">
              <td class="text-center" v-on:click="indexViewImg = index">
                <b-avatar :src="fullPathFile(item.img)" class="elevation-2" size="38px" variant="default" />
              </td>
              <td>
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <router-link class="text-bold" v-bind:to="'/student/detail/' + item.id">
                      {{ item.first_name }} {{ item.last_name }}
                    </router-link>
                    <span class="text-sm float-right">
                      {{ getTextOption(item.status.toString(), statusStudentOptions) }}
                    </span>
                    <div class="text-sm"><i class="fas fa-birthday-cake mr-2"></i>{{ formatDate(item.dob) }}</div>
                    <div>
                      <span v-if="item.parent_name"><i class="fas fa-user-tie mr-2"></i>{{ item.parent_name }}</span>
                      <i class="text-sm ml-1" v-if="item.phone_number">-
                        <span class="ml-1 text-nowrap">{{ formatPhone(item.phone_number) }}</span>
                      </i>
                      <div v-if="item.address"><i class="text-sm">{{ item.address }}</i></div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div><i class="fas fa-futbol mr-2"></i>{{ item.stadium_name }}</div>
                    <div><i class="text-sm">{{ item.stadium_address }}</i></div>
                    <div v-if="item.tuition_debt == 1">
                      <i class="fas fa-dollar-sign text-sm mr-2"></i>
                      <i class="text-sm text-bold text-danger" v-if="item.tuition_debt == 1">Nợ học phí</i>
                      <!-- <i class="text-success text-sm" v-else>Đã đóng</i> -->
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <Paging v-if="items" :items="items" column="9" :callBack="pagingBy" />
        </table>
      </div>
    </div>

    <b-modal centered scrollable no-close-on-backdrop :id="modalIdStudent"
      :title="itemChange && itemChange.id ? 'Cập nhật' : 'Thêm mới'" :size="itemPay ? 'xl' : ''">
      <div class="row admin-page" v-if="itemChange">
        <div :class="itemPay ? 'col-sm-6' : 'col-sm-12'">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold label-valid">Họ & đệm</label>
                <input type="text" class="form-control" v-model="itemChange.first_name" id="first_name"
                  placeholder="Họ & đệm" autocomplete="off">
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold label-valid">Tên</label>
                <input type="text" class="form-control" v-model="itemChange.last_name" id="last_name" placeholder="Tên"
                  autocomplete="off">
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold label-valid">Ngày sinh</label>
                <input type="date" class="form-control" v-model="itemChange.dob" id="dob" placeholder="Ngày sinh"
                  autocomplete="off">
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Tình trạng</label>
                <b-form-select id="status" v-model="itemChange.status" :options="statusStudentOptions">
                </b-form-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Tên phụ huynh</label>
                <input type="text" class="form-control" v-model="itemChange.parent_name" id="parent_name"
                  placeholder="Tên phụ huynh" autocomplete="off">
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Số điện thoại</label>
                <input type="number" class="form-control" v-model="itemChange.phone_number" id="phone_number"
                  placeholder="Số điện thoại" autocomplete="off" maxlength="11">
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Địa chỉ</label>
                <input type="text" class="form-control" v-model="itemChange.address" id="address" placeholder="Địa chỉ"
                  autocomplete="off">
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Ảnh</label>
                <UploadImage ref="uploadImage" :callBack="uploadSuccess"></UploadImage>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6" v-if="itemPay">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group mb-0">
                <label class="control-label text-semibold label-valid">Sân vận động</label>
                <InputStadium ref="inputStadium" :province="userLogin.province_id" :district="userLogin.district_id"
                  :callBack="setStadium" />
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold label-valid">Tháng</label>
                <div class="input-group">
                  <input type="number" class="form-control" v-model="itemPay.month" id="month" placeholder="Tháng"
                    autocomplete="off" min="1" max="12" style="width: 42%;">
                  <input type="number" class="form-control" v-model="itemPay.year" id="year" placeholder="Năm"
                    autocomplete="off" min="2000" max="3000" style="width: 58%;">
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Học phí/tháng</label>
                <InputCurrency id="money" v-model="itemPay.money" placeholder="Học phí/tháng"></InputCurrency>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Tình trạng</label>
                <b-form-select id="status" v-model="itemPay.status" :options="statusPayOptions">
                </b-form-select>
              </div>
            </div>
            <div class="col-sm-12">
              <table class="table mb-1 mt-1 size-website">
                <thead>
                  <tr>
                    <th colspan="2">Lịch học</th>
                    <th style="width: 50px; height: 48px;">
                      <button class="btn btn-success btn-sm" v-on:click="addCalendar()"
                        v-if="dayInWeekOptions.length > itemPay.calendar_data.length">
                        <i class="fas fa-plus-circle"></i>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="itemPay.calendar_data">
                  <tr v-for="(item, index) in itemPay.calendar_data" :key="index">
                    <td>
                      <b-form-select id="day_in_week" v-model="item.day_in_week" :options="dayInWeekOptions">
                      </b-form-select>
                    </td>
                    <td>
                      <input type="time" class="form-control" v-model="item.start_time" id="start_time"
                        autocomplete="off">
                    </td>
                    <td>
                      <div class="btn-group">
                        <button class="btn btn-default btn-sm" v-on:click="deleteCalendar(index)"><i
                            class="far fa-trash-alt"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table mb-1 mt-1 size-mobile">
                <thead>
                  <tr>
                    <th>Lịch học</th>
                    <th style="width: 50px; height: 48px;">
                      <button class="btn btn-success btn-sm" v-on:click="addCalendar()"
                        v-if="dayInWeekOptions.length > itemPay.calendar_data.length">
                        <i class="fas fa-plus-circle"></i>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="itemPay.calendar_data">
                  <tr v-for="(item, index) in itemPay.calendar_data" :key="index">
                    <td>
                      <b-form-select id="day_in_week" v-model="item.day_in_week" :options="dayInWeekOptions">
                      </b-form-select>
                      <input type="time" class="form-control mt-1" v-model="item.start_time" id="start_time"
                        autocomplete="off">
                    </td>
                    <td>
                      <div class="btn-group">
                        <button class="btn btn-default btn-sm" v-on:click="deleteCalendar(index)"><i
                            class="far fa-trash-alt"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label text-semibold">Ghi chú</label>
                <textarea class="form-control" rows="3" placeholder="Ghi chú" v-model="itemPay.note"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <button class="btn btn-info btn-sm" @click="saveItem(itemChange, itemPay)">
          <i class="fas fa-save mr-2"></i><span>Lưu</span>
        </button>
        <button class="btn btn-default btn-sm" @click="hideModal(modalIdStudent)">
          <i class="far fa-window-close mr-2"></i><span>Hủy</span>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiStudent from "../../services/apiStudent";
import { statusStudentOptions, dayInWeekOptions, statusPayOptions } from "../../common/constants";
import { getAdminAuthen } from "../../common/storage";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  components: {
    CoolLightBox,
    Paging: () => import("../components/Paging.vue"),
    Order: () => import("../components/Order.vue"),
    UploadImage: () => import("../components/UploadImage.vue"),
    InputCurrency: () => import("../components/InputCurrency.vue"),
    InputStadium: () => import("../components/InputStadium.vue"),
  },
  data() {
    return {
      userLogin: null,
      coolLightImages: [],
      indexViewImg: null,
      showFilter: false,
      itemFilter: null,
      itemPaging: null,
      itemOrders: [{ column: "first_name", type: "asc" }],
      items: null,
      modalIdStudent: "student-modal",
      statusStudentOptions: statusStudentOptions,
      filterStatusStudentOptions: statusStudentOptions,
      filterMoneyOptions: [
        { value: "", text: 'Tất cả' },
        { value: "0", text: 'Đã đóng' },
        { value: "1", text: 'Nợ học phí' }
      ],
      statusPayOptions: statusPayOptions,
      dayInWeekOptions: dayInWeekOptions,
      stadiumItem: { value: "", text: 'Chọn' },
      itemChange: null,
      itemPay: null,
    };
  },
  methods: {
    setStadiumFilter(provinceId, districtId, stadiumId) {
      console.log(provinceId, districtId, stadiumId);
      this.itemFilter.stadium = stadiumId;
      this.itemFilter.province = provinceId;
      this.itemFilter.district = districtId;
    },
    setStadium(provinceId, districtId, stadiumId) {
      console.log(provinceId, districtId, stadiumId);
      this.itemPay.stadium_id = stadiumId;
    },
    makeItemAddress() {
      return { province: "", district: "", stadium: "" };
    },
    makeItemFilter(user) {
      return { name: "", status: "", tuition_debt: "", stadium: "", province: user ? user.province_id : "", district: user ? user.district_id : "" };
    },
    showHideFilter(value) {
      this.showFilter = !value;
    },
    clearFilter() {
      if (this.$refs && this.$refs.inputStadium)
        this.$refs.inputStadium.setStadium(this.makeItemAddress());
      this.itemFilter = this.makeItemFilter();
      this.initItems();
    },
    keyEnter: function (e) {
      if (e.which == 13) this.initItems();
    },
    uploadSuccess(files) {
      this.itemChange.img = "";
      if (files && files.length > 0) this.itemChange.img = files[0].path;
    },
    setImage(images) {
      setTimeout(() => {
        if (this.$refs && this.$refs.uploadImage) this.$refs.uploadImage.setImage(images);
      }, 500);
    },
    async initItems() {
      this.showLoading(true);
      const data = await ApiStudent.getPaging(this.itemPaging, this.itemOrders, this.itemFilter);
      this.showLoading(false);
      this.coolLightImages = [];
      if (data && data.items) {
        this.items = data;
        this.items.items = data.items.map(e => {
          this.coolLightImages.push(this.fullPathFile(e.img));
          return e;
        });
      }
    },
    pagingBy(paging) {
      this.itemPaging = paging;
      this.initItems();
    },
    orderBy(orders) {
      this.itemOrders = orders;
      this.initItems();
    },
    makeItem() {
      return { id: "", img: "", first_name: "", last_name: "", dob: "", status: "0", phone_number: "", parent_name: "", address: "" };
    },
    makeItemPay() {
      const date = new Date();
      return { student_id: "", stadium_id: "", month: date.getMonth() + 1, year: date.getFullYear(), money: "0", number_of_day: "", status: "0", calendar_data: [], note: "" };
    },
    makeItemCalendar() {
      return { day_in_week: "1", start_time: "07:00" };
    },
    addItem() {
      this.itemChange = this.makeItem();
      this.itemPay = this.makeItemPay();
      this.addCalendar();
      this.showModal(this.modalIdStudent);
    },
    async updateItem(item) {
      this.itemChange = this.setObjectValue(this.makeItem(), item);
      this.itemPay = null;
      this.showModal(this.modalIdStudent);
      this.setImage([this.itemChange.img]);
    },
    checkValid(item) {
      console.log("item", item);
      if (item) return this.checkValidForm([
        { id: "first_name", value: item.first_name, message: 'Vui lòng nhập Họ & đệm' },
        { id: "last_name", value: item.last_name, message: 'Vui lòng nhập Tên' },
        { id: "dob", value: item.dob, message: 'Vui lòng nhập Ngày sinh' },
        // { id: "phone_number", value: item.phone_number, message: 'Vui lòng nhập Số điện thoại' },
        // { id: "phone_number", value: this.formatPhone(item.phone_number), message: 'Nhập số điện thoại chưa đúng' },
        // { id: "parent_name", value: item.parent_name, message: 'Vui lòng nhập Tên phụ huynh' }
      ]);
      return false;
    },
    checkValidPay(item) {
      console.log("itemPay", item);
      if (item) return this.checkValidForm([
        { id: "stadium", value: item.stadium_id, message: 'Vui lòng nhập Sân vận động' },
        { id: "month", value: item.month, message: 'Vui lòng nhập Tháng' },
        { id: "year", value: item.year, message: 'Vui lòng nhập Năm' }
      ]);
      return false;
    },
    async saveItem(item, itemPay) {
      console.log("saveItem", item, itemPay);
      if (this.checkValid(item) && ((itemPay && this.checkValidPay(itemPay)) || !itemPay)) {
        this.showLoading(true);
        const data = await ApiStudent.addOrUpdate(item);
        this.checkResponse(data, async () => {
          if (itemPay) {
            itemPay.student_id = data.id;
            itemPay.number_of_day = itemPay.calendar_data.length;
            const dataPay = await ApiStudent.addOrUpdatePay(itemPay);
            this.showLoading(false);
            this.checkResponse(dataPay, () => {
              this.hideModal(this.modalIdStudent);
              this.initItems();
            });
          } else {
            this.showLoading(false);
            this.hideModal(this.modalIdStudent);
            this.initItems();
          }
        }, () => { this.showLoading(false); });
      }
    },
    async deleteItem(item) {
      if (!item.id) return;
      this.confirmDelete(this.$route.name, `${item.first_name} ${item.last_name}`).then(async (result) => {
        if (!result) return;
        this.showLoading(true);
        const data = await ApiStudent.delete(item.id);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.initItems();
        });
      })
    },
    addCalendar() {
      if (this.itemPay) this.itemPay.calendar_data.push(this.makeItemCalendar());
    },
    deleteCalendar(index) {
      if (this.itemPay) this.itemPay.calendar_data.splice(index, 1);
    },
  },
  mounted() {
    this.filterStatusStudentOptions.unshift({ value: "", text: 'Tất cả' });
    this.userLogin = getAdminAuthen();
    this.itemFilter = this.makeItemFilter(this.userLogin);
    this.initItems();
    if (this.$route.query && this.$route.query.action == "add") {
      setTimeout(() => {
        this.addItem();
        this.$router.push({ path: '/student' });
      }, 500);
    }
  }
};
</script>
